import { onMount } from "solid-js";
import AppWebView from "~/utils/app_web_view/trigger_app_events";

export default function Rakshabandhan() {
  onMount(() => {
    //set doc bg to white
    // document.body.style.backgroundColor = "white";
  });

  return (
    <div
      class=" w-full bg-white"
      onClick={() => {
        // @ts-ignore
        AppWebView.deeplinkTrigger(
          "/public/webview/?title=&url=https://vouchers.dev.myhubble.money/app/diwali&showAppBar=false"
        );
      }}
    >
      <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
      <div class="aspect-video h-full scale-110  bg-white">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://lottie.host/47db8513-3c56-4afc-bf1a-35b6c8d269d0/OhF82Y9pOX.json"
        ></lottie-player>
      </div>
    </div>
  );
}
